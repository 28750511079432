import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage3Cookie_note from "./pages/Webpage3Cookie_note";
import Webpage4Page_not_found from "./pages/Webpage4Page_not_found";
import Webpage9Men_____vaated from "./pages/Webpage9Men_____vaated";
import Webpage11Menu_templates from "./pages/Webpage11Menu_templates";
import Webpage13Home from "./pages/Webpage13Home";

var baseURL = 'https://lilleorumtu.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNGYxMjNkOTc4YzJiMTkwNDRjZTJjOWViYTY0ODc2MmIxYTViMTVlY2ZiMWIzN2ZiNWQyNWZlMmI2NTg4ZDlhYTY3NTI2MDY4MDZlM2U2Y2MiLCJpYXQiOjE3MzI5MTgyNjcuNTE3NTM4LCJuYmYiOjE3MzI5MTgyNjcuNTE3NTQyLCJleHAiOjE3MzMwMDQ2NjcuNTE0MjkzLCJzdWIiOiI2NyIsInNjb3BlcyI6W119.06LiRaqrDyuLUAYGaOZYGUiE-nm8SWt5UDeTQ8KHJaeSMSPMejFDpqwt-nSsHTmez2ln9kA7n0lpjZCUHZyCbeZ_t2wz4GSPZtZtyEE0O9R7iS-eJlp7JzUHzlayjRS6fHHgx54obYwTi-u1iLU22IrHpZ3JyWYESCuvZdu3i1vDBwyJmuuo82iSVIMzhPe4qBDR9elL2zQha-PIopsLFP5yH6gpb55vXLIgQZSsH9X_z3CPIqz5Hpj5VIFrvSIZmCn0BUZiq2jb1RFkLkpClsb_u0PxUwUT8N5xIP7hT2EZjiKauODEcTWTlQWg0zdsE7X_DCPwY9e9OaUy6JWzEZ7Wi98nenoqq9rYcApvp8ZKf3ggJk9LEDzj9QZN1l734PkUqNZUSpxgzYs3DsTbotYivOWs0f1sdpNEedeCCI6a2ZA-jQsj622Mm6bF2RVf4ArS5e6E5nE1d8ZFegdD7tDdd132EXP726MBuvhqm-FuRwvKzbkKrklNcGDY0WuLtj2yiGdHSNuWtaz3ZEHAnr1qrzdC6L8J1Yi1f3eS3fzUwET3ASHnnOD1UWbp4PjXXrlHxxJaqdCylu7-y8IpEBu8HKo3bIPxmEEqoB0Ssk0fhhQRNXPPXmeOwiavJTNYx0zIfo8cFYPLA2djm_Rsh2BKNPWJ-Oqb-U9NZg9TPLI',
    'Access-api-key': "eyJpdiI6Im9ISXBpdWE0cm85Q2Q2ZThieUdOb3c9PSIsInZhbHVlIjoibDg5b0tBc25ybXBXUEpxeVhqcXljdz09IiwibWFjIjoiMzhkZjQ1YWQ0MjhhZmY1MzFhNTY5OTZmZmY1Y2JhMDA1ZjE4NjdkNDZiZTg1YzA1MDkwNDQxZTRkZjZhMzU1ZSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Ik5JTW16U2NPS1dnWVJRcVFJeWxSMnc9PSIsInZhbHVlIjoiNmxLbUljdlYxUkJrSkh3UzU2Q084Zz09IiwibWFjIjoiZDZhMDA1YjQ4ZDE1ZjVhMTRkZDBkOWExODg0OTI2ODFlZDM5NjhhYTEzM2FkMGNkZDU1NWE0ZTQzZWE5YTA2ZCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/en/cookie-note" element={<Webpage3Cookie_note />} exact />;
                                                                    <Route path="/en/*" element={<Webpage4Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage4Page_not_found />} exact />;
                                                                    <Route path="/et/templates" element={<Webpage9Men_____vaated />} exact />;
                                                                    <Route path="/en/templates" element={<Webpage11Menu_templates />} exact />;
                                                                    <Route path="/en/" element={<Webpage13Home />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}